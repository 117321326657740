import BeniftContent from "./BeniftContent";

const Benifits = () => {
  return (
    <>
      {/* <!-- Benefits --> */}
      <section className="py-24 dark:bg-jacarta-900">
        <div className="container">
          <div className="mx-auto mb-16 text-center">
            <h2 className="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl text-center uppercase">
              IMPACT DOTS
            </h2>
            <h3 className="mb-2 block font-display text-xl font-medium group-hover:text-accent dark:text-white  transition-colors text-center">
              How Campaigns Work
            </h3>
            <p className="text-lg dark:text-jacarta-300">
              Our Impact DOT (digital ownership token) campaigns are a great way
              to collaborate to raise funds with mutual benefits. Here&apos;s an
              overview of how it works.
            </p>
            {/* <p className="text-lg dark:text-jacarta-300">
              We empower artists, creators, and players to build the platform
              they always envisioned, providing the means to unleash your
              creativity and earn income.
            </p> */}
          </div>
          <BeniftContent />
        </div>
      </section>
      {/* <h2 className="mb-5 font-display text-3xl text-jacarta-700 dark:text-white text-center">
        Launch a campaign
      </h2> */}
      {/* <!-- end benefits --> */}
    </>
  );
};

export default Benifits;
